
.slider {
  -webkit-appearance: none;
  margin-left: 5%;
  width: 90%;
  height: 10px;
  border-radius: 10px;
  background-image: linear-gradient(to right, red , yellow, green);
  outline: none;
  opacity: 0.9;
  -webkit-transition: .2s;
  transition: opacity .2s;
}
  
  
.slider::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 2px;
}

/* datalist {
    display: flex;
    justify-content: space-between;
    color: black;
    width: 100%;
} */
