.progress-bar {
	height:10px;
	border-radius: 24px;    
}

.progress-bar--gradient {
	background: linear-gradient(to right, red 0, orange 25%, yellow 50%, yellowgreen 75%, green 100%);
	border-radius: 24px;
	height: inherit;
	width: 100%;
	display: flex;
}

.progress-bar--not-progress {
	flex-grow: 1;
	height: inherit;
	background: #ccc;
	border-top-right-radius: 24px;
	border-bottom-right-radius: 24px; 
}